import React from 'react';
import PropTypes from 'prop-types';
import Introduction from '@greenberry/salal/components/Introduction';
import Section from '@greenberry/salal/components/Section';
import Button from '@greenberry/salal/components/Button';
import Icon from '@greenberry/salal/components/Icon';
import Card from '@greenberry/salal/components/Card';
import Heading from '@greenberry/salal/components/Heading';
import CardToggle from '@greenberry/salal/components/CardToggle';
import SlideIntoView from '../SlideIntoView';
import chartTitleGenerator from '../../utils/chartTitleGenerator';

export const OVERVIEW = 'OVERVIEW';
export const HISTORY = 'HISTORY';

const IndicatorSection = ({
  children,
  title,
  body,
  indicator,
  selectedView = OVERVIEW,
  onViewChange = f => f,
  disableViewToggle = false,
  filter,
}) => (
  <Section size="2">
    <Introduction title={title} text={body} />
    {disableViewToggle ? (
      <br />
    ) : (
      <CardToggle
        filter={filter}
        toggle={
          <>
            <Button
              onClick={() => onViewChange(OVERVIEW)}
              color={selectedView === OVERVIEW ? 'primary' : 'secondary'}
              styling="toggle"
              type="button"
            >
              <Icon size="lg" type="chartBar" />
              <span>Overzicht</span>
            </Button>
            <Button
              color={selectedView === HISTORY ? 'primary' : 'secondary'}
              styling="toggle"
              type="button"
              onClick={() => onViewChange(HISTORY)}
            >
              <Icon size="lg" type="chartLine" />
              <span>Historie</span>
            </Button>
          </>
        }
      />
    )}
    <SlideIntoView minHeight="100px">
      <Card>
        <Heading component="h2" size="xl" appearance="secondary">
          {chartTitleGenerator(indicator)}
        </Heading>
        {children}
      </Card>
    </SlideIntoView>
  </Section>
);

IndicatorSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  indicator: PropTypes.string.isRequired,
  onViewChange: PropTypes.func,
  selectedView: PropTypes.oneOf([OVERVIEW, HISTORY]),
  disableViewToggle: PropTypes.bool,
  filter: PropTypes.node,
};

export default IndicatorSection;
