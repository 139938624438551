import { reverse, map, prop, pipe } from 'ramda';
import sortByYear from '../../../../utils/sortByYear';
import getLatestYear from '../../../../utils/getLatestYear';

const formatHistory = indicator => {
  const partnershipLabels = map(pipe(getLatestYear, prop('label')), indicator);
  const years = reverse(sortByYear(Object.keys(indicator[0])));

  const data = years.map(year => ({
    label: year.split('/')[0],
    ...partnershipLabels.reduce((acc, label, index) => {
      acc[label] = indicator[index][year].values[0].value;
      return acc;
    }, {}),
  }));

  return data;
};

export default formatHistory;
