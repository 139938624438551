import React, { useState } from 'react';
import { map, filter, concat, pluck } from 'ramda';
import PropTypes from 'prop-types';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import styled from '@emotion/styled';
import theme from '@greenberry/salal/theme';
import Button from '@greenberry/salal/components/Button';
import GroupedBarChart from '@greenberry/salal/components/Charts/GroupedBar';
import LineChart from '@greenberry/salal/components/Charts/Line';
import Legend from '@greenberry/salal/components/Legend';
import { categoriesStudentCount } from '../../../constants/studentCategories';
import getLatestYear from '../../../utils/getLatestYear';
import isMobile from '../../../utils/isMobile';
import {
  withIndicators,
  comparingSector,
} from '../../../state/selectedForComparison';
import IndicatorSection, { OVERVIEW, HISTORY } from '../../IndicatorSection';
import StudentCountTick from '../../StudentCountTick';
import IndicatorLoading from '../../IndicatorLoading';

import formatHistory from '../utils/formatHistory';
import {
  formatStudentCount,
  formatNationalStudentCount,
  formatLegend,
} from './utils';

const INDICATOR_NAME = 'studentCount';

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > button {
    margin-right: ${theme.spacing('s')};
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const StudentCount = ({ nationalStudentCount, dataSource, ...props }) => {
  const [selectedView, setSelectedView] = useState(OVERVIEW);
  const partnerships = useRecoilValueLoadable(withIndicators);
  const sector = useRecoilValue(comparingSector);
  const initialFilters = pluck(
    'altKey',
    categoriesStudentCount[sector || 'primair-onderwijs']
  );
  const [activeFilters, setActiveFilters] = useState(initialFilters);
  if (partnerships.state === 'loading')
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Laden…"
      />
    );
  if (partnerships.contents.length === 0)
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Geen samenwerkingsverbanden geselecteerd"
      />
    );

  const currentSector = partnerships.contents[0].sector;

  const formattedIndicator = partnerships.contents.map(partnership =>
    formatStudentCount(partnership, activeFilters)
  );
  const latestYear = formattedIndicator.map(getLatestYear);
  const nationalAsPartnership = formatNationalStudentCount(
    nationalStudentCount,
    currentSector,
    activeFilters
  );

  const overviewData = [...latestYear, getLatestYear(nationalAsPartnership)];
  const historyData = formatHistory([
    ...formattedIndicator,
    nationalAsPartnership,
  ]);

  return (
    <>
      <IndicatorSection
        selectedView={selectedView}
        onViewChange={setSelectedView}
        indicator={INDICATOR_NAME}
        filter={
          <>
            <span>Filter op:</span>
            <FilterWrapper>
              {map(
                ({ label, altKey: key }) => (
                  <Button
                    small
                    key={key}
                    onClick={() => {
                      const update = activeFilters.includes(key)
                        ? filter(k => key !== k, activeFilters)
                        : concat(activeFilters, [key]);

                      setActiveFilters(
                        update.length === 0 ? initialFilters : update
                      );
                    }}
                    color={
                      activeFilters.length === 0 || activeFilters.includes(key)
                        ? 'primary'
                        : 'tertiary'
                    }
                  >
                    {label}
                  </Button>
                ),
                categoriesStudentCount[sector]
              )}
            </FilterWrapper>
          </>
        }
        {...props}
      >
        {selectedView === OVERVIEW && (
          <GroupedBarChart
            data={overviewData}
            xKey="label"
            height={450}
            unit="leerlingen"
            yTick={StudentCountTick}
            tooltip
          />
        )}
        {selectedView === HISTORY && (
          <LineChart
            showTitle={isMobile()}
            data={historyData}
            height={isMobile() ? 400 : 600}
            xKey="label"
            yTick={StudentCountTick}
          />
        )}
        <Legend
          source={dataSource}
          data={selectedView === HISTORY ? formatLegend(overviewData) : []}
          appearance={selectedView === HISTORY ? 'circle' : 'cube'}
          alignment={selectedView === HISTORY ? 'column' : 'grid'}
        />
      </IndicatorSection>
    </>
  );
};

export const nationalStudentCountShape = PropTypes.arrayOf(
  PropTypes.shape({
    sector: PropTypes.oneOf(['PO', 'VO']).isRequired,
    studentCount: PropTypes.shape({
      BO: PropTypes.number,
      SBO: PropTypes.number,
      SO__bekostigd_: PropTypes.number,
      PRO: PropTypes.number,
      VO: PropTypes.number,
      VSO__bekostigd_: PropTypes.number,
    }),
  })
);

StudentCount.propTypes = {
  dataSource: PropTypes.string.isRequired,
  nationalStudentCount: PropTypes.shape({
    _2019_2020: nationalStudentCountShape,
    _2018_2019: nationalStudentCountShape,
    _2017_2018: nationalStudentCountShape,
    _2016_2017: nationalStudentCountShape,
    _2015_2016: nationalStudentCountShape,
    _2014_2015: nationalStudentCountShape,
  }).isRequired,
};

export default StudentCount;
