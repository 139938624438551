import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Section from '@greenberry/salal/components/Section';
import Introduction from '@greenberry/salal/components/Introduction';
import formatYear from '../../utils/formatYear';
import formatDataSource from '../../utils/formatDataSource';

import ComparisonLayout from '../../components/ComparisonLayout';

import StudentCount, {
  nationalStudentCountShape,
} from '../../components/Comparison/StudentCount';
import ParticipationRate, {
  nationalParticipationRateShape,
} from '../../components/Comparison/ParticipationRate';

const ComparisonStudents = ({
  data: {
    copy,
    nationalParticipationRate: { participationRate: nationalParticipationRate },
    nationalStudentCount: { studentCount: nationalStudentCount },
    dataSourceStudentCount,
    dataSourceParticipationRate,
  },
}) => {
  return (
    <ComparisonLayout seoMetaTags={copy.seoMetaTags}>
      <Section size="2">
        <Introduction title={copy.title} text={copy.intro} />
      </Section>

      <StudentCount
        title={copy.studentCountTitle}
        body={copy.studentCountBody}
        dataSource={formatDataSource(dataSourceStudentCount)}
        nationalStudentCount={Object.keys(nationalStudentCount).reduce(
          (acc, curr) => {
            acc[formatYear(curr)] = nationalStudentCount[curr];
            return acc;
          },
          {}
        )}
      />
      <ParticipationRate
        title={copy.participationRateTitle}
        body={copy.participationRateBody}
        dataSource={formatDataSource(dataSourceParticipationRate)}
        nationalParticipationRate={Object.keys(
          nationalParticipationRate
        ).reduce((acc, curr) => {
          acc[formatYear(curr)] = nationalParticipationRate[curr];
          return acc;
        }, {})}
      />
    </ComparisonLayout>
  );
};

ComparisonStudents.propTypes = {
  data: PropTypes.shape({
    dataSourceStudentCount: PropTypes.string.isRequired,
    dataSourceParticipationRate: PropTypes.string.isRequired,
    copy: PropTypes.shape({
      title: PropTypes.string.isRequired,
      intro: PropTypes.string.isRequired,
      studentCountTitle: PropTypes.string.isRequired,
      studentCountBody: PropTypes.string.isRequired,
      participationRateTitle: PropTypes.string.isRequired,
      participationRateBody: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({
        tags: PropTypes.array,
      }).isRequired,
    }).isRequired,
    nationalParticipationRate: PropTypes.shape({
      participationRate: PropTypes.shape({
        _2019_2020: nationalParticipationRateShape,
        _2018_2019: nationalParticipationRateShape,
        _2017_2018: nationalParticipationRateShape,
        _2016_2017: nationalParticipationRateShape,
        _2015_2016: nationalParticipationRateShape,
        _2014_2015: nationalParticipationRateShape,
      }),
    }),
    nationalStudentCount: PropTypes.shape({
      studentCount: PropTypes.shape({
        _2019_2020: nationalStudentCountShape,
        _2018_2019: nationalStudentCountShape,
        _2017_2018: nationalStudentCountShape,
        _2016_2017: nationalStudentCountShape,
        _2015_2016: nationalStudentCountShape,
        _2014_2015: nationalStudentCountShape,
      }),
    }),
  }).isRequired,
};

export default ComparisonStudents;

export const query = graphql`
  query getStudents {
    dataSourceStudentCount: datoCmsDataSource(
      indicator: { eq: "Aantal leerlingen" }
    ) {
      source
      date
    }
    dataSourceParticipationRate: datoCmsDataSource(
      indicator: { eq: "Deelname percentage" }
    ) {
      source
      date
    }
    copy: datoCmsComparisonStudent {
      title
      intro
      studentCountTitle
      studentCountBody
      participationRateTitle
      participationRateBody
      seoMetaTags {
        tags
      }
    }
    nationalParticipationRate: nationalIndicator(
      key: { eq: "participationRate" }
    ) {
      participationRate {
        _2021_2022 {
          LWOO
          PRO
          SBO
          SO
          VSO
        }
        _2020_2021 {
          LWOO
          PRO
          SBO
          SO
          VSO
        }
        _2019_2020 {
          LWOO
          PRO
          SBO
          SO
          VSO
        }
        _2018_2019 {
          LWOO
          PRO
          SBO
          SO
          VSO
        }
        _2017_2018 {
          LWOO
          PRO
          SBO
          SO
          VSO
        }
        _2016_2017 {
          LWOO
          PRO
          SBO
          SO
          VSO
        }
        _2015_2016 {
          LWOO
          PRO
          SBO
          SO
          VSO
        }
        _2014_2015 {
          LWOO
          PRO
          SBO
          SO
          VSO
        }
      }
    }
    nationalStudentCount: nationalIndicator(key: { eq: "studentCount" }) {
      studentCount {
        _2021_2022 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2020_2021 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2019_2020 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2018_2019 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2017_2018 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2016_2017 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2015_2016 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
        _2014_2015 {
          sector
          studentCount {
            BO
            SBO
            SO__bekostigd_
            PRO
            VO
            VSO__bekostigd_
          }
        }
      }
    }
  }
`;
