import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@greenberry/salal/components/Heading';
import IndicatorSection from '../IndicatorSection';

const IndicatorLoading = ({ message = 'Laden…', ...props }) => (
  <IndicatorSection {...props}>
    <Heading>{message}</Heading>
  </IndicatorSection>
);

IndicatorLoading.propTypes = {
  message: PropTypes.string.isRequired,
};

export default IndicatorLoading;
