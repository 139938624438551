import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import Legend from '@greenberry/salal/components/Legend';
import LineChart from '@greenberry/salal/components/Charts/Line';
import GroupedBarChart from '@greenberry/salal/components/Charts/GroupedBar';
import MultiButton from '@greenberry/salal/components/MultiButton';
import { categoriesParticipationRate } from '../../../constants/studentCategories.js';

import isMobile from '../../../utils/isMobile';
import getLatestYear from '../../../utils/getLatestYear';
import {
  withIndicators,
  comparingSector,
} from '../../../state/selectedForComparison';

import { formatParticipationRate, formatLegend, formatHistory } from './utils';

import IndicatorSection, { OVERVIEW, HISTORY } from '../../IndicatorSection';
import IndicatorLoading from '../../IndicatorLoading';

const INDICATOR_NAME = 'participationRate';

const multiButtonOptions = Object.keys(categoriesParticipationRate).reduce(
  (acc, key) => {
    acc[key] = categoriesParticipationRate[key].map(({ label, key }) => ({
      label,
      value: key,
    }));

    return acc;
  },
  {}
);

const ParticipationRate = ({
  nationalParticipationRate,
  dataSource,
  ...props
}) => {
  const [selectedView, setSelectedView] = useState(OVERVIEW);
  const sector = useRecoilValue(comparingSector);
  const [activeMultiButton, setActiveMultiButton] = useState(
    sector ? multiButtonOptions[sector][0] : sector
  );

  const partnerships = useRecoilValueLoadable(withIndicators);
  if (partnerships.state === 'loading')
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Laden…"
      />
    );
  if (partnerships.contents.length === 0)
    return (
      <IndicatorLoading
        {...props}
        indicator={INDICATOR_NAME}
        message="Geen samenwerkingsverbanden geselecteerd"
      />
    );

  const formattedIndicator = partnerships.contents.map(partnership =>
    formatParticipationRate(partnership)
  );
  const nationalAsPartnership = formatParticipationRate(
    {
      indicators: { participationRate: nationalParticipationRate },
      sector: partnerships.contents[0].sector,
      shortName: 'Landelijk gemiddelde',
    },
    true
  );

  const overviewData = [
    ...formattedIndicator.map(getLatestYear),
    getLatestYear(nationalAsPartnership),
  ];

  const historyData = formatHistory(
    [...formattedIndicator, nationalAsPartnership],
    activeMultiButton
  );
  return (
    <IndicatorSection
      selectedView={selectedView}
      onViewChange={setSelectedView}
      indicator={
        INDICATOR_NAME +
        (selectedView === HISTORY ? `-${activeMultiButton.label}` : '')
      }
      filter={
        selectedView === HISTORY && (
          <>
            <span>Filter op:</span>
            <MultiButton
              active={activeMultiButton}
              color="primary"
              horizontal
              onChange={setActiveMultiButton}
              options={multiButtonOptions[sector]}
              styling="solid"
            />
          </>
        )
      }
      {...props}
    >
      {selectedView === OVERVIEW && partnerships.length !== 0 && (
        <GroupedBarChart
          data={overviewData}
          xKey="label"
          height={450}
          unit="percentage"
          tooltip
        />
      )}
      {selectedView === HISTORY && (
        <LineChart
          showTitle={isMobile()}
          data={historyData}
          height={isMobile() ? 400 : 600}
          xKey="label"
          unit="percentage"
        />
      )}
      <Legend
        source={dataSource}
        data={formatLegend(overviewData, selectedView)}
        appearance={selectedView === HISTORY ? 'circle' : 'cube'}
        alignment={selectedView === HISTORY ? 'column' : 'float'}
      />
    </IndicatorSection>
  );
};

export const nationalParticipationRateShape = PropTypes.shape({
  LWOO: PropTypes.number.isRequired,
  PRO: PropTypes.number.isRequired,
  SBO: PropTypes.number.isRequired,
  SO: PropTypes.number.isRequired,
  VSO: PropTypes.number.isRequired,
});

ParticipationRate.propTypes = {
  dataSource: PropTypes.string.isRequired,
  nationalParticipationRate: PropTypes.shape({
    _2019_2020: nationalParticipationRateShape,
    _2018_2019: nationalParticipationRateShape,
    _2017_2018: nationalParticipationRateShape,
    _2016_2017: nationalParticipationRateShape,
    _2015_2016: nationalParticipationRateShape,
    _2014_2015: nationalParticipationRateShape,
  }),
};

export default ParticipationRate;
