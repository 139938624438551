import { filter, propEq, reduce } from 'ramda';
import theme from '@greenberry/salal/theme';
import { sectors } from '../../../../backend/constants';
import sortByYear from '../../../utils/sortByYear';
import sumStudentCount from '../../../utils/sumStudentCount';
import { categoriesStudentCount } from '../../../constants/studentCategories';

const getFormFilter = activeFilters => indicator =>
  activeFilters.reduce((acc, curr) => {
    acc[curr] = indicator[curr];
    return acc;
  }, {});

export const formatStudentCount = (partnership, activeFilters) => {
  const {
    indicators: { studentCount },
  } = partnership;
  const formFilter = getFormFilter(activeFilters);

  const data = reduce(
    (acc, year) => {
      acc[year] = {
        label: partnership.shortName,
        values: [
          {
            label: 'leerlingen',
            value: sumStudentCount(formFilter(studentCount[year])),
          },
        ],
      };

      return acc;
    },
    {},
    sortByYear(Object.keys(studentCount))
  );

  return data;
};

export const formatLegend = partnerships =>
  partnerships.map(({ label }, index) => ({
    label,
    color: theme.color(`chart.${index + 1}`),
  }));

export const formatNationalStudentCount = (
  studentCount,
  sector,
  activeFilters
) => {
  const formFilter = getFormFilter(
    activeFilters.map(
      key =>
        categoriesStudentCount[sectors[sector]].find(
          ({ altKey }) => altKey === key
        ).key
    )
  );
  const data = reduce(
    (acc, year) => {
      const yearOfSector = filter(propEq('sector', sector), studentCount[year]);
      acc[year] = {
        label: 'Landelijk gemiddelde',
        values: [
          {
            label: 'leerlingen',
            striped: true,
            opacity: 0.8,
            value: Math.round(
              reduce(
                (acc, { studentCount: partnership }) =>
                  (acc += sumStudentCount(formFilter(partnership))),
                0,
                yearOfSector
              ) / yearOfSector.length
            ),
          },
        ],
      };

      return acc;
    },
    {},
    sortByYear(Object.keys(studentCount))
  );

  return data;
};
