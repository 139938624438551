import theme from '@greenberry/salal/theme';

// https://greenberry.atlassian.net/browse/POVOPO-143
export const categoriesStudentCount = {
  'voortgezet-onderwijs': [
    {
      label: 'Regulier',
      key: 'VO',
      altKey: 'VO',
      color: theme.color('chart.1'),
    },
    {
      label: 'Speciaal',
      key: 'VSO__bekostigd_',
      altKey: 'VSO (bekostigd)',
      color: theme.color('chart.2'),
    },
    {
      label: 'PRO',
      key: 'PRO',
      altKey: 'PRO',
      color: theme.color('chart.3'),
    },
  ],
  'primair-onderwijs': [
    {
      label: 'Regulier',
      key: 'BO',
      altKey: 'BO',
      color: theme.color('chart.1'),
    },
    {
      label: 'Speciaal basisonderwijs',
      key: 'SBO',
      altKey: 'SBO',
      color: theme.color('chart.2'),
    },
    {
      label: 'Speciaal onderwijs',
      key: 'SO__bekostigd_',
      altKey: 'SO (bekostigd)',
      color: theme.color('chart.3'),
    },
  ],
};

// https://greenberry.atlassian.net/browse/POVOPO-143
export const categoriesParticipationRate = {
  'voortgezet-onderwijs': [
    {
      label: 'pro',
      key: 'PRO',
      altKey: 'PRO',
      color: theme.color('chart.1'),
    },
    {
      label: 'lwoo',
      key: 'LWOO',
      altKey: 'LWOO',
      color: theme.color('chart.2'),
    },
    {
      label: 'vso - bekostigd',
      key: 'VSO',
      altKey: 'VSO',
      color: theme.color('chart.3'),
    },
  ],
  'primair-onderwijs': [
    {
      label: 'sbo',
      key: 'SBO',
      altKey: 'SBO',
      color: theme.color('chart.1'),
    },
    {
      label: 'so - bekostigd',
      key: 'SO',
      altKey: 'SO',
      color: theme.color('chart.2'),
    },
  ],
};
