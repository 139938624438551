import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import SubNavigation from '@greenberry/salal/components/SubNavigation';
import MainWrapper from '@greenberry/salal/components/MainWrapper';

import Layout from '../Layout';
import SEO from '../SEO';
import trackIndicatorInView from '../../utils/trackIndicatorInView';

const getLocation = () => {
  if (global.window) {
    return global.window.location.pathname;
  }
  return '/';
};

const ComparisonLayout = ({
  children,
  seoMetaTags = {
    tags: [],
  },
  selectedIndicator = '',
  ...props
}) => {
  const location = getLocation();

  useEffect(() => {
    if (selectedIndicator !== '') {
      trackIndicatorInView(selectedIndicator);
    }
  }, [selectedIndicator]);

  return (
    <Layout {...props}>
      <SEO tags={seoMetaTags.tags} />
      <SubNavigation
        items={[
          {
            id: 'link 1',
            active: location === '/vergelijken' || location === '/vergelijken/',
            render: (
              <Link to="/vergelijken/">
                <span>Algemene informatie</span>
              </Link>
            ),
          },
          {
            id: 'link 2',
            active: location.includes('leerlingen'),
            render: (
              <Link to="/vergelijken/leerlingen">
                <span>Leerlingen</span>
              </Link>
            ),
          },
          {
            id: 'link 3',
            active: location.includes('financien'),
            render: (
              <Link to="/vergelijken/financien">
                <span>Financiën</span>
              </Link>
            ),
          },
          {
            id: 'link 4',
            active: location.includes('organisatie'),
            render: (
              <Link to="/vergelijken/organisatie">
                <span>Organisatie</span>
              </Link>
            ),
          },
        ]}
      />
      <MainWrapper>{children}</MainWrapper>
    </Layout>
  );
};

ComparisonLayout.propTypes = {
  children: PropTypes.node.isRequired,
  seoMetaTags: PropTypes.shape({ tags: PropTypes.arrayOf(PropTypes.object) }),
  selectedIndicator: PropTypes.string,
};

export default ComparisonLayout;
