import React from 'react';
import PropTypes from 'prop-types';
import formatValue from '@greenberry/salal/utils/formatValue';

const Tick = ({ x, y, index, payload }) => {
  if (index === 0) {
    return false;
  }
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={5} textAnchor="end" fill="#666">
        {payload.value <= 100000
          ? formatValue(payload.value)
          : `${formatValue(payload.value / 1000)}k`}
      </text>
    </g>
  );
};

Tick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  index: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.number,
  }),
};

export default Tick;
