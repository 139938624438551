import { reverse, reduce, isNil, map, prop, pipe } from 'ramda';
import theme from '@greenberry/salal/theme';
import sortByYear from '../../../utils/sortByYear';
import getLatestYear from '../../../utils/getLatestYear';
import { sectors } from '../../../../backend/constants';
import { categoriesParticipationRate } from '../../../constants/studentCategories';
import { HISTORY } from '../../IndicatorSection';

export const formatParticipationRate = (partnership, stripe = false) => {
  const sector = sectors[partnership.sector];
  const {
    indicators: { participationRate },
  } = partnership;
  const data = reduce(
    (acc, year) => {
      const formsInSector = categoriesParticipationRate[sector];
      acc[year] = {
        label: partnership.shortName,
        values: formsInSector.map(({ key, label }, index) => ({
          label,
          striped: stripe,
          opacity: stripe ? 0.8 : 1,
          color: theme.color(`chart.${index + 1}`),
          value: isNil(participationRate[year][key])
            ? 0
            : participationRate[year][key],
        })),
      };

      return acc;
    },
    {},
    sortByYear(Object.keys(participationRate))
  );

  return data;
};

export const formatLegend = (partnerships, selectedView) => {
  if (selectedView === HISTORY) {
    return partnerships.map(({ label }, index) => ({
      label,
      color: theme.color(`chart.${index + 1}`),
    }));
  }

  return partnerships[0].values.map(({ color, label }) => ({
    label,
    color,
  }));
};
export const formatHistory = (indicator, filter) => {
  const partnershipLabels = map(pipe(getLatestYear, prop('label')), indicator);
  const years = reverse(sortByYear(Object.keys(indicator[0])));

  const data = years.map(year => ({
    label: year.split('/')[0],
    ...partnershipLabels.reduce((acc, label, index) => {
      const curr = indicator[index][year].values.find(
        ({ label: x }) => x === filter.label
      );
      acc[label] = curr.value;
      return acc;
    }, {}),
  }));

  return data;
};
